import React, { useState } from 'react';
import { Modal, Box, Typography, TextField, Popper, Skeleton } from '@mui/material';
import { useGetList } from 'react-admin';
import { IoMdSearch } from 'react-icons/io';
import { DnDArticles } from './DnDArticles';
import { useSelector } from 'react-redux';
import { Card } from './Card';

const ModalFeaturedPosts = ({openModal, setOpenModal,info,categoryPicked}) => {
  const palette = useSelector((state) => state?.palette?.palette);
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '60%',
    height: '70%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    overflowY: 'auto',
    borderRadius: '10px 5px 5px 10px',
    '&::-webkit-scrollbar': {
      width: '8px',
      backgroundColor: 'rgba(0, 0, 0, 0.2)', 
      borderRadius: '20px', 
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: palette?.primary,
      borderRadius: '20px', 
    }
  };
  const [filterValue, setFilterValue] = useState('');
  const [categoryFilter, setCategoryFilter] = useState('');
  const { data: allArticles, isFetched } = useGetList('articles', {
    pagination: {
      perPage: 100,
      page: 1
    },
  });
  const {data:posts,isFetched:isFetchedPost} =useGetList('articles', {
    pagination: {
      perPage: 100,
      page: 1
    },
    filter: {
      categories:[categoryPicked && categoryPicked]
    }
  });
  const handleNameFilter = (event) => {
    setFilterValue(event.target.value);
  };

  const handleCategoryFilter = (event) => {
    setCategoryFilter(event.target.value);
  };

  // Filtrar los artículos basados en el valor del filtro
  const filteredArticles = allArticles?.filter(article =>
    article.title.toLowerCase().includes(filterValue.toLowerCase()) &&
    (categoryFilter === '' || article.category === categoryFilter)
  );
  
  return (
    <Modal
      open={openModal}
      onClose={() => setOpenModal(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{overflowY:'auto'}}
    >
      <Box sx={style}>
        <button onClick={() => setOpenModal(false)} style={{position:'absolute',top:10,right:10,width:30,height:30,display:'flex',alignItems:'center',justifyContent:'center',borderRadius:5,backgroundColor:'white',cursor:'pointer',border:'none'}}>X</button>
        <div style={{display:'flex',flexDirection:'row',gap:10}}>
          {isFetched && <TextField
            sx={{ width: '100%', backgroundColor: 'white' }}
            value={filterValue}
            variant="outlined"
            hiddenLabel={true}
            onChange={handleNameFilter}
            InputProps={{
              style: {
                borderRadius: '10px',
                fontSize: '14px',
              },
              startAdornment: <IoMdSearch size={24} />,
            }}
          />}
          <FilterPosts from='articles' handleCategoryFilter={handleCategoryFilter} categoryFilter={categoryFilter}/>
        </div>
        <div>
          <Typography fontWeight={600} fontSize={18}>FEATURED ARTICLES <span style={{color:'gray',fontSize:14}}>{'(UP TO 10)'}</span></Typography>
          {isFetched && 
            <DnDArticles
              text={'Texto de prueba'}
              info={info}
              options={posts}
            />}
        </div>
        <div style={{marginTop:20,width:'100%'}}>
          <Typography fontWeight={600} fontSize={18}>ALL ARTICLES</Typography>
          <div style={{width:'100%',marginTop:5, display:'flex',flexDirection:'row',flexWrap:'wrap',gap:20}}>
            {isFetched ? filteredArticles?.map((post, index) => (
              <Card
                key={post.id}
                item={post}
                index={index}
                // moveCard={moveCard}
              />
            )):
              <div style={{width:'100%',marginTop:5, display:'flex',flexDirection:'row',flexWrap:'wrap',gap:20}}>
                <Skeleton variant="rounded" width={200} height={120} />
                <Skeleton variant="rounded" width={200} height={120} />
                <Skeleton variant="rounded" width={200} height={120} />
              </div>
            }
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export const renderPopper = (params) => <Popper sx={{minWidth: '190px', fontSize: '.9em'}} {...params} />;

export const FilterPosts = ({ handleCategoryFilter,categoryFilter }) => {

  const { data, isFetching } = useGetList('articles/categories', { pagination: {perPage:9999, page: 1}, sort: { field: 'rank', order: 'DESC'}});

  return (
    <div style={{width:'100%'}}>
      <TextField
        select
        sx={{ width: '40%', backgroundColor: 'white' }}
        value={categoryFilter}
        variant="outlined"
        hiddenLabel={true}
        onChange={handleCategoryFilter}
        InputProps={{
          style: {
            borderRadius: '50px',
            fontSize: '14px',
          },
        }}
        SelectProps={{
          native: true,
        }}
      >
        <option value="">All Categories</option>
        {data?.map(category => (
          <option key={category.id} value={category.text}>{category.text}</option>
        ))}
      </TextField>
    </div>
      
  );
};
export default ModalFeaturedPosts;
import React, {
  Fragment,
  memo,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Box, Skeleton, Tooltip, Typography } from '@mui/material';
import { BiTrashAlt } from 'react-icons/bi';
import { useSelector } from 'react-redux';
import { Button } from '../../../../Components/Generics';

export const createObj = (arr, value = '') => {
  if (arr) {
    return arr?.map((item, i) => {
      return { key: i, value: item };
    });
  } else {
    return [{ key: 0, value: value }];
  }
};

// Format el objeto para pasar al input
export const formatObj = (arr) => {
  return arr?.map((item) => item.value);
};

export const initialRender = (num, element, initialValue) => {
  let arr = [
    {
      key: 0,
      component: React.cloneElement(element, { ...element?.props, key: 0 }),
      value: initialValue,
    },
  ];
  for (let i = 1; i < num; i++) {
    let elem = {
      key: i,
      component: React.cloneElement(element, { ...element?.props, key: i }),
      value: initialValue,
    };
    arr.push(elem);
    continue;
  }
  return arr;
};

export const AddButton = ({
  addIcon,
  addText = '+ Add',
  handle,
  sx,
  ...rest
}) => (
  <Button
    {...rest}
    onClick={handle}
    style={{
      borderRadius: '12px',
      ...sx,
    }}
  >
    {!addIcon && (
      <Typography
        variant="h7"
        sx={{
          textTransform: sx?.['textTransform'] ?? 'none',
          color: 'black',
          fontWeight: '600',
          ...rest.typosx,
        }}
      >
        {addText}
      </Typography>
    )}
  </Button>
);

export const RemoveButton = ({ handleDelete, componentKey, sx, ...rest }) => (
  <Button
    {...rest}
    onClick={() => handleDelete(componentKey)}
    style={{
      borderRadius: '12px',
      padding: '15px',
      height: '50px',
      alignSelf: 'center',
      // marginBottom: componentKey === 0 ? '0px' : '23px',
      marginLeft: '5px',
      ...sx,
    }}
  >
    <BiTrashAlt color={rest.disabled ? '' : '#f99190'} />
  </Button>
);

export const InputAddTextInputs = memo(({
  tooltip,
  onDelete,
  element,
  buttonVariant,
  checkVal,
  values = [],
  setValues,
  addText,
  addAction,
  limit = 3,
  width = '100%',
  disable,
  direction = 'row',
  sx,
  boxDirection = 'column',
  boxDisplay = 'flex',
  hideAdd,
  outSideBox,
  hideRemove,
  initialNumber = 1,
  initialValue = null,
  modify,
  gap = '10px',
  removeAside,
  addBelow,
  noPadding,
  noDisableRemove,
  addSx,
  disableRemove,
  addIcon,
  boxSx,
  scroll,
}) => {
  const palette = useSelector((state) => state?.palette?.palette);
  const [newValues, setNewValues] = useState(values);
  const [arr, setArr] = useState(
    initialRender(initialNumber, element, initialValue),
  );
  const containerRef = useRef(null);
  const handle = () => {
    if (!addAction) {
      if (scroll) {
        if (containerRef.current) {
          containerRef.current.scrollTo({
            top: containerRef.current.scrollHeight,
            behavior: 'smooth',
          });
        }
      }
      return setArr([
        ...arr,
        {
          key: arr.length,
          component: React.cloneElement(element, {
            ...element?.props,
            key: arr.length,
          }),
          value: initialValue,
        },
      ]);
    }

    const actionResult = addAction && addAction();
    if (addAction && !actionResult) return;
    if (actionResult) {
      if (scroll) {
        if (containerRef.current) {
          containerRef.current.scrollTo({
            top: containerRef.current.scrollHeight,
            behavior: 'smooth',
          });
        }
      }
      setArr([
        ...arr,
        {
          key: arr.length,
          component: React.cloneElement(element, {
            ...element?.props,
            key: arr.length,
          }),
          value: initialValue,
        },
      ]);
    }
    setNewValues([...newValues, actionResult]);
    return;
  };

  const updatedArr = (arr, key) => {
    let index = Number(key);
    let updatedArr = arr.map((item) => {
      let i = Number(item.key);
      if (i === index) {
        return null;
      } else if (i > index) {
        if (typeof item.key === 'string') {
          return { ...item, key: (i - 1).toString() };
        }
        return { ...item, key: Number(i - 1) };
      } else {
        return item;
      }
    });
    let filteredArr = updatedArr.filter((item) => item !== null);

    return filteredArr;
  };

  const handleDelete = (componentKey) => {
    onDelete && onDelete(componentKey);
    setArr(updatedArr(arr, componentKey));
    setNewValues(updatedArr(newValues, componentKey));
  };

  useEffect(() => {
    if (values.length !== newValues.length) {
      setArr(initialRender(initialNumber, element, initialValue));
      setNewValues(values);
    }
  }, [initialNumber]);

  const elementProps = (element, index) => {
    return {
      key: `input-component-${index}`,
      componentKey: element.component.key,
      newValues: newValues,
      handleChange: setNewValues,
      deleteHandle: handleDelete,
      checkVal: checkVal,
      index: index,
    };
  };
  useEffect(() => {
    setValues && setValues(newValues);
  }, [newValues]);

  const handleDisableRemove = useCallback(
    (item, i) => {
      if (!noDisableRemove) return Boolean(i + 1 !== arr.length);
      return disableRemove ? disableRemove(item) : false;
    },
    [arr],
  );
  return (
    <Fragment key="input-add-text-fragment">
      {arr && values && (
        <Tooltip
          key="input-add-text-tooltip"
          placement="top-end"
          title={tooltip ? <span>{tooltip}</span> : <span></span>}
        >
          <Fragment key="input-add-text-tooltip-fragment">
            <Box
              ref={containerRef}
              key="input-add-text-box"
              sx={{
                display: boxDisplay,
                flexDirection: boxDirection,
                width: width,
                gap: gap,
                ...boxSx,
              }}
            >
              {arr ? (
                arr?.map((el, i) => {
                  // Agregue el modify , que lo envia el componente padre para especificar que se trata de agregar mas TMs
                  if (
                    i + 1 === arr.length &&
                    (modify === 'talent_managers'
                      ? arr.length !== 999
                      : arr.length !== limit)
                  ) {
                    return (
                      <Fragment key="input-add-text-innter-fragment">
                        <Box
                          sx={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: direction,
                            ...sx,
                          }}
                        >
                          {React.cloneElement(el.component, {
                            ...elementProps(el, i),
                          })}
                          {removeAside && i > 0 && !hideRemove && (
                            <RemoveButton
                              disabled={handleDisableRemove(
                                newValues[i] ?? el,
                                i,
                              )}
                              handleDelete={handleDelete}
                              componentKey={i}
                            />
                          )}
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'row',
                              gap: '15px',
                            }}
                          >
                            {i > 0 && !hideRemove && !removeAside && (
                              <RemoveButton
                                disabled={handleDisableRemove(
                                  newValues[i] ?? el,
                                  i,
                                )}
                                handleDelete={handleDelete}
                                componentKey={i}
                              />
                            )}
                            {!hideAdd && !addBelow && (
                              <AddButton
                                addIcon={addIcon}
                                variant={buttonVariant}
                                addText={addText}
                                disabled={
                                  disable
                                    ? values?.length !== arr.length
                                    : false
                                }
                                handle={handle}
                                sx={addSx}
                                startIcon={addIcon}
                                typosx={{ color: palette?.primary }}
                              />
                            )}
                            {i <= 0 && !noPadding && (
                              <Box
                                sx={{
                                  width: '80px',
                                  borderRadius: '12px',
                                  padding: '5px',
                                }}
                              />
                            )}
                          </Box>
                        </Box>
                        {addBelow && !hideAdd && !outSideBox && (
                          <AddButton
                            addIcon={addIcon}
                            variant={buttonVariant}
                            addText={addText}
                            disabled={
                              disable ? values?.length !== arr.length : false
                            }
                            handle={handle}
                            sx={addSx}
                            startIcon={addIcon}
                            typosx={{ color: palette?.primary }}
                          />
                        )}
                      </Fragment>
                    );
                  }
                  return (
                    <Box
                      sx={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: direction,
                        ...sx,
                      }}
                    >
                      {React.cloneElement(el.component, elementProps(el, i))}
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          gap: '15px',
                        }}
                      >
                        {!hideRemove && (
                          <RemoveButton
                            disabled={handleDisableRemove(
                              newValues[i] ?? el,
                              i,
                            )}
                            handleDelete={handleDelete}
                            componentKey={i}
                          />
                        )}
                        {!noPadding && (
                          <Box
                            sx={{
                              width: '80px',
                              borderRadius: '12px',
                              padding: '5px',
                            }}
                          />
                        )}
                      </Box>
                    </Box>
                  );
                })
              ) : (
                <Skeleton />
              )}
            </Box>
            {addBelow && !hideAdd && outSideBox && (
              <AddButton
                variant={buttonVariant}
                addText={addText}
                disabled={disable ? values?.length !== arr.length : false}
                handle={handle}
                sx={addSx}
                startIcon={addIcon}
                typosx={{ color: palette?.primary }}
              />
            )}
          </Fragment>
        </Tooltip>
      )}
    </Fragment>
  );
});

InputAddTextInputs.displayName = 'InputAddTextInputs';

import { Autocomplete, Switch, TextField } from '@mui/material';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { DateTimeInput, useInput } from 'react-admin';
import { InputTitle } from '../StyledComponents/InputTitle';
import { useUtcFormat } from '../../helpers/date';
import { INPUTS_VARIANT } from '../../assets/constants/Form';
import { switchStyleIos } from '../StyledComponents/Inputs';

export const InputOsSelector = ({ source }) => {
  const versiones = ['ios', 'android'];

  const { field } = useInput({ source });
  return (
    <>
      <InputTitle
        sx={{
          fontWeight: 600,
          fontSize: '16px',
          color: '#000',
          marginBottom: '20px',
        }}
      >
        Device OS
      </InputTitle>
      <Autocomplete
        value={field.value || null}
        onChange={(e, v) => field.onChange(v)}
        options={versiones}
        source={source}
        renderInput={(params) => <TextField {...params} label='' />}
      />
    </>
  );
};
export const InputVersionSelector = ({ source }) => {
  const [datos, setDatos] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { field } = useInput({ source });

  useEffect(() => {
    const fetchData = async () => {
      let token = localStorage.getItem('auth');
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_PATH}/devices/versions`,
          {
            headers: {
              'Access-Control-Allow-Origin': '*',
              'Access-Control-Expose-Headers': 'content-range',
              Authorization: 'Bearer ' + token,
              'x-api-key': process.env.REACT_APP_API_KEY,
            },
          }
        );
        const result = await response.data;
        setDatos(result);
        setLoading(false);
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };

    fetchData();
    return () => {
      setDatos(null);
      setLoading(true);
      setError(null);
    };
  }, []);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error loading data: {error}</div>;
  if (datos.length >= 1) {
    return (
      <>
        <InputTitle
          sx={{
            fontWeight: 600,
            fontSize: '16px',
            color: '#000',
            marginBottom: '20px',
          }}
        >
          Device Version
        </InputTitle>
        <Autocomplete
          value={field.value || null}
          onChange={(e, v) => field.onChange(v)}
          options={datos}
          source={source}
          renderInput={(params) => <TextField {...params} label='' />}
        />
      </>
    );
  }
};
export const InputDateTimeSelector = ({ recordScheduled, isEdit }) => {
  const { field } = useInput({ source: 'send_at' });
  const { formatFestivalTime, parseFestivalTime } = useUtcFormat();
  return (
    <>
      <InputTitle
        sx={{
          fontWeight: 600,
          fontSize: '16px',
          color: '#000',
          marginBottom: '20px',
        }}
      >
        Send At
      </InputTitle>
      <DateTimeInput
        format={(e) => formatFestivalTime(e)}
        parse={(e) => parseFestivalTime(e)}
        variant={INPUTS_VARIANT}
        InputProps={{
          sx: {
            borderRadius: '10px',
          },
        }}
        subtitle={'What are you trying to say with this notification'}
        label=''
        source={field.value === '' ? 'date_created' : 'send_at'}
        disabled={
          !isEdit ? false : recordScheduled === 'scheduled' ? false : true
        }
        fullWidth
      />
    </>
  );
};
export const InputBooleanSelector = ({
  source,
  sendNow,
  setSendNow,
  isEdit,
}) => {
  const { field } = useInput({ source });
  const handleSwitch = (e) => {
    const valueCheck = e.target.checked;
    setSendNow(valueCheck);
    field.onChange(valueCheck);
  };

  return (
    <>
      <InputTitle
        sx={{
          fontWeight: 600,
          fontSize: '16px',
          color: '#000',
          marginBottom: '20px',
        }}
      >
        Send Now
      </InputTitle>
      <Switch
        sx={switchStyleIos}
        onChange={handleSwitch}
        checked={sendNow}
        disabled={
          !isEdit ? false : true
        }
      />
    </>
  );
};

import React from 'react';
import { TextField,FunctionField } from 'react-admin';
import { Chip } from '@mui/material';
import ListCustom from '../../Components/ListCustom/ListCustom';
import { Indicator } from '../../Components/RedesignComponents/Indicator';
import dayjs from 'dayjs';
import { strCapitalize } from '../../helpers/strCapitilize';
import { ListActions } from '../../Components/ListActions/ListActions';

const AccessList = () => {

  const paramsList = {
    listParams: {
      hasCreate: false,
      actions: <ListActions search={false} placeholder={'Search by title, subtitle, body and author'}/>,
      // queryOptions: {
      //   meta: { field: 'last_modified'},
      // }
    },
    datagridParams: {
      bulkActionButtons: false,
      rowClick: 'edit'
    }
  };
  return (
    <div>
      <ListCustom {...paramsList} datagridParams={{
        sx: { 
          padding:'0px 20px',
          zIndex:100,
          '& .MuiTypography-root':{
            color:'#000000',
            fontWeight:400
          },
          '& .column-category': {
            width: '20%',
            textAlign: 'center',
          },
          '& .column-title': {
            width: '60%',
          },
          '& .column-author': {
            width: '20%',
          },
        },
        ...paramsList.datagridParams,
      }}>
        <FunctionField source="source" render={(record) => (
          <div style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            padding: "4px",
            border: "1px solid #a6a6a6",
            borderRadius: "8px",
            height: "35px",
            backgroundColor: "#f0f0f0"
          }}>
            {strCapitalize(record.source)}
          </div>
        )} />
        <FunctionField
          label="Status"
          source="status"
          render={(record) => (
            <div
              style={{
                display: 'flex',
                flexDirection:"row",
                justifyContent: 'start',
                alignItems: 'start',
                width: "100%",
              }}
            >
              {record?.status === 'draft' ||
              record?.status === null ||
              record?.status === 'not_submitted' ? (
                  <Indicator data={'not_submitted'} />
                ) : (
                  <Indicator data={record?.status} />
                )}
            </div>
          )}
        />
        <FunctionField source="status_detail" render={(record) => strCapitalize(record.status_detail)} />
        <FunctionField
          label="Date created"
          source="date"
          render={(record) => dayjs(record.date).format(
            'DD/MM/YYYY - HH:mm:ss',
          )}
        />
        <TextField source="last_modified_by" />
        {/* <FunctionField label="Category" source="category" 
          render={(record)=> record?.category ? <Chip sx={{width:'auto'}} label={record?.category} /> : null }/>  */}
      </ListCustom>
    </div>
  );
};

export default AccessList;
import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { useInput } from 'react-admin';
import { useSelector } from 'react-redux';
import { InputTitle } from '../../../../Components/StyledComponents/InputTitle';
import { InputSubtitle } from '../../../../Components/StyledComponents/InputSubtitle';
import styles from './radio.module.css';
import { useFormContext } from 'react-hook-form';


export const RadioInput = (props) => {

  const {
    source,
    title,
    subtitle,
    index
  } = props;
  const { field } = useInput({ source });
  const { getValues } = useFormContext();
  const [inputValue, setInputValue] = useState(field.value[index]?.multiselect ?? false);
    
  const handleClick = (item) => {
    setInputValue(item);
  };
  useEffect(() => {
    setInputValue(field.value[index]?.multiselect ?? false);
  }, [index, getValues('id')]);
  useEffect(() => {
    const arr = field.value?.map((item, i)=> {
      if(i === index) {
        return {
          ...item, 
          multiselect: inputValue
        };
      }
      return item;
    });
    field.onChange(arr);
  },[inputValue]);
  
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start',
        width: '100%',
      }}
    >
      {title && <InputTitle>{title}</InputTitle>}
      {subtitle && <InputSubtitle>{subtitle}</InputSubtitle>}
      <RadioInputChoices {...props} handleClick={handleClick} inputValue={inputValue} />
    </Box>
  );
};

export const RadioInputChoices = (props) => {
  const {
    key = 'name',
    value = 'value',
    direction = 'row',
    sx,
    choices,
    handleClick,
    inputValue
  } = props;
  const palette = useSelector((state) => state?.palette?.palette);

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: direction,
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      ...sx
    }}>
      {
        choices?.map((item, index) => {
          return (
            <button 
              key={`button-${index}`}
              id={`button-${index}`}
              type="button"
              onClick={() => handleClick(item[value])}
              className={styles['radio-button']}
              style={{
                backgroundColor: item[value] === inputValue? palette.tertiary : '#fff',
                color: item[value] === inputValue? '#fff' : '#000000',
                borderRadius: index === 0 ? '6px 0px 0px 6px' : index === (choices.length - 1) ? '0px 6px 6px 0px' : null
              }}>
              {item[key]}
            </button>
          );
        })
      }
    </Box>
  );
};
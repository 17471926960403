import React, { useEffect, useMemo } from 'react';
import { useGetList ,useInput} from 'react-admin';
import InputAutocomplete from '../../InputsForm/InputAutocomplete';
import { useFormContext } from 'react-hook-form';
import { Box, Skeleton } from '@mui/material';
import { useSelector } from 'react-redux';

const parsedLinking = {
  talents: 'lineup/artist',
  events: 'events/event',
  articles: 'home/post',
  locations: 'venue/location',
  excursions: 'more/excursions',
  faqs: 'more/faqs',
  more: 'more'
};

export const InputDeepLinking = ({
  choices,
  approvedFields,
  textTransform,
  title,
  watchField,
  optionText = 'text',
  optionValue = 'text',
  source,
  linking,
  appConfig,
  ...rest
}) => {
  const { field } = useInput({ source });
  
  // Este valor es unicamente local, nunca se guarda en la bd, me sirve para tener una referencia a como crear la URL.
  let linkingPage = linking;
  // Este valor lo utilizo para rellenar en los edits el defaultValue
  const contructUrl = () => {
    let url = '';
    if (linkingPage) {
      url = appConfig && `${appConfig[0]?.app_slug}://${parsedLinking[linkingPage.toLowerCase()]}`;
    }
    return url;
  };
  const pickedOptionText = () => {
    switch (linkingPage) {
    case 'Articles':
      return 'title';
    case 'FAQs':
      return 'question';
    default :
      return 'name';
    }
  };
 
  const setFormValue = (value,record) => {
 
    let baseUrl = contructUrl();
    if(value === '-' || value === ''){
     
      const finalUrl = `${baseUrl}`;
      field.onChange(finalUrl);
    }else{
      const finalUrl = `${baseUrl}/${value}`;
      field.onChange(finalUrl);
    }
  };
  const { data, isFetching, refetch } = useGetList(linkingPage && `${linkingPage.toLowerCase()}`, { pagination: {perPage:9999, page: 1}, sort: { field: 'rank', order: 'DESC'}},  { enabled: false});

  useEffect(() => {
    refetch();
  },[linkingPage]);
  
  const defaultData = () => {
    
    switch (linkingPage) {
    case 'Articles':
      return [{id:'list',title:'List'},...data];
    case 'FAQs':
      return [{id:'list',question:'List'},...data];
    case 'Events':
      return [{id:'list', name:'List'},{id:'timeline', name:'Schedule'},...data];
    default :
      return [{id:'list',name:'List'},...data];
    }
  };

  const parseUrl = (str) => {
    let result = str.split('/');
    return result[result.length-1];
  };
  console.log(parseUrl(field.value));
  useEffect(() => {
    field.onChange(contructUrl());
  },[linking]);

  return (
    <Box sx={{
      width: '100%',
      display: 'flex',
      alignItems: 'cemnter',
      justifyContent: 'center'
    }}>
      {linkingPage !== 'Excursions' && linkingPage !== 'FAQs' && linkingPage !== 'More' ?
        data && !isFetching ?(        
          <InputAutocomplete
            // defaultValue={valueUrl}
            defaultValue={defaultData()?.find(item => item?.id === parseUrl(field.value))}
            source='events'
            choices={linkingPage !== 'More' && linkingPage !== 'Excursions' && linkingPage !== 'FAQs' &&
            defaultData()}
            freeSolo={false}
            optionText={pickedOptionText()}
            label=""
            onChange={setFormValue}
            {...rest}
          />
        ): <Skeleton variant="rectangular" width={300} height={40} sx={{marginTop:1, borderRadius: '10px' }} />
        :
        <InputAutocomplete
          defaultValue={defaultData()?.find(item => item?.id === parseUrl(field.value))}
          
          source=''
          choices={[{ id: '-', name: '-'}]}
          freeSolo={false}
          optionText={'name'}
          label=""
          onChange={setFormValue}
          {...rest}
        />
      }
    </Box>
  );
};


export const CardAll = ({ item, onDrag, onDragOver, setIsDragging }) => {
  return (
    <div
      id={item.id}
      onDragEnd={() => {
        // setIsDragging(false);
      }}
      onDragStart={(e) => {
        onDrag(e, item.id, item);
      }}
      //   onDragLeave={() => setIsDragging(true)}
      onDragOver={onDragOver}
      draggable="true"
      style={{
        height: '120px',
        position: 'relative',
        width: '200px',
        borderRadius: '10px',
        cursor: 'grab',
      }}
    >
      <img
        src={item?.picture?.original}
        alt="postImagen"
        style={{
          width: '200px',
          height: '120px',
          objectFit: 'cover',
          borderRadius: '10px',
          position: 'relative',
        }}
      />
      <div
        style={{
          position: 'absolute',
          bottom: '0',
          left: '0',
          width: '200px',
          padding: '10px',
          borderRadius: '10px',
          zIndex: 1,
        }}
      >
        <p
          style={{
            color: 'white',
            fontSize: '14px',
            fontWeight: 700,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
        >
          {item?.title}
        </p>
        <p
          style={{
            fontSize: '13px',
            fontWeight: 400,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            color: 'white',
          }}
        >
          {item?.subtitle}
        </p>
      </div>
      <div
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          borderRadius: '10px',
          overflow: 'hidden',
        }}
      >
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            borderRadius: '10px',
            zIndex: 0,
          }}
        ></div>
      </div>
    </div>
  );
};
import axios from 'axios';
// Hooks & Utils
import { useCustomToasty } from '../../../../Hooks/useCustomToasty';
// Components
import AdaptableFormRedesign from '../../../../Components/AdaptableForm/AdaptableFormRedesign';
// Material & Icons
import { Typography} from '@mui/material';
import InputDndArticles from '../../../../Components/RedesignComponents/Inputs/InputDnDArticles.jsx';
import { InputTitle } from '../../../../Components/StyledComponents/InputTitle.jsx';

export const FeaturedForm = () => {
  const customToast = useCustomToasty();
  const data = [
    {
      inputs: [
        {
          field: (
            <>
              <InputDndArticles
                source="articles_ranks"
                reference="articles/categories"
                label=""
                title={'Selected articles'}
                subtitle={
                  'You will see the articles you’ve previously selected. Drag them to order them as you want them to appear'
                }
              />
            </>
          ),
          breakpoints: { xs: 12 },
        },
      ],
    },
  ];
  
  const onSubmit = async (data) => {
    if(data.articles_ranks){
      const postData = data.articles_ranks.map((item) => ({
        id: item.id,
        rank: item.rank
      }));
      axios({
        url: `${process.env.REACT_APP_BASE_PATH}/articles`, 
        method: 'PUT',
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Expose-Headers': 'content-range',
          Authorization: 'Bearer ' + localStorage.getItem('auth'),
          'x-api-key': process.env.REACT_APP_API_KEY,
        },
        data: {
          articles:[
            ...postData
          ]
        },
      })
        .then((res) => {
          customToast('success', 'Changes submitted succesfuly.');
        })
        .catch((err) => {
          customToast('error', err);
        });
    }
  };

  return {
    data: data,
    onSubmit: onSubmit
  };
};

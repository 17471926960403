import { Alert, Box, MenuItem, Select, Typography } from '@mui/material';
import { InputTitle } from '../../../../Components/StyledComponents/InputTitle';
import { InputSubtitle } from '../../../../Components/StyledComponents/InputSubtitle';
import { DEEP_LINKING_OPTIONS } from '../../../../assets/constants/types';
import { InputDeepLinking } from '../../../../Components/RedesignComponents/Inputs';
import { useGetList, useInput, useRecordContext } from 'react-admin';
import { useCallback, useState } from 'react';
import InputText from '../../../../Components/InputsForm/InputText';
import { RadioInput, RadioInputChoices } from '../../../Interests/Form/Components/RadioInput';


export const DeepLinkingComponent = ({ source }) => {
  const record = useRecordContext();
  const recordScheduled = record?.status;
  const { field } = useInput({ source:'internal_deeplink'});
  const [type, setType] = useState(
    record
      ? record?.internal_deeplink === true
        ? 'Internal'
        : 'External'
      : undefined
  );
  const [linking, setLinking] = useState(undefined);
  const { data: appConfig }= useGetList('app_config', {pagination: {perPage:9999, page: 1}, sort: { field: 'rank', order: 'DESC'}});
  
  const handleSelectChange = useCallback((event) => {
    console.log('asdasd');
    setLinking(event.target.value);
  }, []);
    

  return (
    <>
      <RadioInputChoices
        source="internal_deeplink"
        sx={{
          maxWidth: '300px',
          alignItems: 'flex-start',
          alignSelf: 'flex-start'
        }}
        choices={[{ name: 'Internal', value: true}, {name: 'External', value: false}]}
        inputValue={field?.value}
        handleClick={field.onChange}
      />
      {/* <InputTabSingleSelector
        setLinking={setLinking}
        setState={setType}
        state={type}
        label=''
        title='TYPE'
        options={[
          { id: 1, text: 'Internal' },
          { id: 2, text: 'External' },
        ]}
        fullWidth
        source='internal_deeplink'
        resource='notifications'
        validate={[required('The internal deeplink is required.')]}
        disabled={
          !record ? false : recordScheduled === 'scheduled' ? false : true
        }
      /> */}
      {appConfig && appConfig[0]?.app_slug === null && (
        <Alert
          severity='warning'
          style={{ backgroundColor: '#FFF8B7', width: 'fit-content' }}
        >
                  App Slug has not been created, please create one to send
                  internally linked notifications.
        </Alert>
      )}
      <InputTitle sx={{ marginTop: '30px' }}>
                INTERNAL LINKING
      </InputTitle>
      <InputSubtitle>
                If your notification links to an internal page in the app you
                need to specify it
      </InputSubtitle>
      <Box sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%'
      }}>
        {
          type === 'Internal' ? (
            <Box sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%'
            }}>
              <Select
                disabled={
                  !record ? false : recordScheduled === 'scheduled' ? false : true
                }
                value={linking}
                onChange={handleSelectChange}
                size='small'
                fullWidth
                sx={{ borderRadius: '10px' }}
              >
                {DEEP_LINKING_OPTIONS.map((option) => (
                  <MenuItem key={option.id} value={option.text}>
                    {option.text}
                  </MenuItem>
                ))}
              </Select>
              <InputDeepLinking
                linking={linking}
                optionValue={'text'}
                optionText={'text'}
                fullWidth
                source='url'
                title={'Type'}
                label={''}
                disabled={
                  !record ? false : recordScheduled === 'scheduled' ? false : true
                }
                appConfig={appConfig}
              />
            </Box>
          ) : (
            <InputText
              title={
                <Typography fontWeight={600} fontSize={'16px'} color={'#000'}>
                  URL
                </Typography>
              }
              subtitle={'Link to external content'}
              label=''
              source='url'
              fullWidth
              disabled={
                !record ? false : recordScheduled === 'scheduled' ? false : true
              }
            />
          )
        }
      </Box>
    </>
       
  );
};
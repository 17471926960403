import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useGetList, useInput } from 'react-admin';
import axios from 'axios';
import { FilterPosts } from './ModalFeaturedPosts';
import { useCustomToasty } from '../../Hooks/useCustomToasty';
import { IconButton, Card as CardGeneric } from '../Generics/index';
import { RiCloseCircleFill } from 'react-icons/ri';
import { Box, Button, Modal, Skeleton, TextField, Typography } from '@mui/material';
import { IoMdSearch } from 'react-icons/io';
import { TbHandMove } from 'react-icons/tb';
import { useSelector } from 'react-redux';
import styles from './OrderArticles.module.css';
import { Card } from './Card';
import { CardAll } from './CardAll';
import { useOnClickOutside } from '../../Hooks/useOnClickOutside';
import { isDraft } from '@reduxjs/toolkit';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '60%',
  height: '70%',
  bgcolor: 'background.paper',
  boxShadow: 24,
  padding: '30px',
  overflowY: 'auto',
  borderRadius: '10px 5px 5px 10px',
};

const styleBox = {
  display: 'flex',
  flexDirection: 'row',
  overflowX: 'scroll',
  overflowY: 'hidden',
  paddingY: '10px',
  gap: '10px',
  alignItems: 'center',
  '&::-webkit-scrollbar': {
    height: '10px',
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
    borderRadius: '20px',
  },
  '&::-webkit-scrollbar-thumb': {
    borderRadius: '20px',
  },
};


export const DnDArticles = ({ options = [], isModal, source }) => {
  const { field } = useInput({ source, defaultValue: [...options].sort((a, b) => a?.rank - b?.rank )});
  const palette = useSelector((state) => state?.palette?.palette);


  const [hoverCard, setHoverCard] = useState(null);
  const [targetId, setTargetId] = useState('');
  const [parseElement, setParseElement] = useState(null);
  const customToast = useCustomToasty();
  // const [cards, setCards] = useState(null);
  const [filterValue, setFilterValue] = useState('');
  const [categoryFilter, setCategoryFilter] = useState('');
  const [openModal, setOpenModal] = useState(false);

  const { data: allArticles, isFetched } = useGetList('articles', {
    pagination: {
      perPage: 100,
      page: 1,
    },
  });
  const moveCard = (idDraggElement, idHoverElement) => {
    let cards = [...field.value] ?? [];
    console.log('asdasd');
    const elIndxDraggeadoIndex = cards.findIndex(
      (item) => item.id === idDraggElement,
    );
    const elIndxHoverIndex = cards.findIndex(
      (item) => item.id === idHoverElement,
    );
    const newCards = [...cards];
    const [draggedCard] = newCards.splice(elIndxDraggeadoIndex, 1);
    newCards.splice(elIndxHoverIndex, 0, draggedCard);
    newCards.forEach((card, index) => {
      card.rank = index;
    });
    // setCards(newCards);
    field.onChange(newCards);
  };

  const handlerModal = (e) => {
    e.stopPropagation();
    setOpenModal(prev => prev ? prev : !prev);
  };

  const moveCardModal = (element, idDraggElement, idHoverElement) => {
    let cards = field?.value;
    const elIndxHoverIndex = cards.findIndex(
      (item) => item.id === idHoverElement,
    );
    const newCards = [...cards];
    const index = newCards.findIndex((card) => card.id === element.id);
    if (index !== -1) {
      if (index !== elIndxHoverIndex) {

        newCards.splice(index, 1);
        // setCards(newCards);
        let rankActual = 0;
        newCards?.forEach((card, index) => {
          card.rank = index;
          if (card.rank > rankActual) {
            rankActual = card.rank;
          }
        });
        newCards.sort((a, b) => a.rank - b.rank);
        newCards.push(element);
        element.rank = newCards.length - 1;
        // setCards(newCards);
        field.onChange(newCards);

      }
    } else {
      const fullCards = cards.filter((card) => card.rank !== null);
      if (fullCards?.length < 10) {

        newCards.push(element);
        // setCards(newCards);
        newCards[newCards.length - 1].rank = newCards?.length - 1;

        // setCards(newCards);

        field.onChange(newCards);
      } else {
        customToast('error', 'You can not add more than 10 articles');
      }
    }
  };
  const handleNameFilter = (event) => {
    setFilterValue(event.target.value);
  };
  const handleCategoryFilter = (event) => {
    setCategoryFilter(event.target.value);
  };
  const filteredArticles = allArticles?.filter(
    (article) =>
      article.title.toLowerCase().includes(filterValue.toLowerCase()) &&
      (categoryFilter === '' || article.category === categoryFilter),
  );
  const filteredWithCards =
    field?.value &&
    filteredArticles?.filter(
      (post) =>
        !field?.value.some((card) => card?.id === post?.id && card?.rank !== null),
    );
  const onSubmit = useCallback(() => {
    let values = [...field.value];
    if(!Array.isArray(values) || values.length <= 0) {
      return;
    } else {
      values = values.map((obj, index) => ({id: obj.id, rank: index + 1}));
    }
    setOpenModal(false);
  },[field?.value]);

  const onDrag = (e, id, item) => {
    e.dataTransfer.setData('text/plain', id);
    if (item) {
      e.dataTransfer.setData('item', JSON.stringify(item));
      setParseElement(item);
    }
  };

  const onDragOver = (e) => {
    e.stopPropagation();
    e.preventDefault();

    if (!e.currentTarget) {
      return;
    }
    const hoveredId = e.currentTarget.id;
    setTargetId(hoveredId);
    setHoverCard(hoveredId);
  };

  const handleDelete = (id) => {
    let values = [...field.value] ?? [];
    field.onChange(values.filter(item => item.id !== id).map((item, index) => {
      if(index + 2 === item.rank) return({...item, rank: index});
      return item;
    }));
  };

  const drop = (e, modalOn) => {
    e.preventDefault();
    setHoverCard(null);
    let cards = [...field.value] ?? [];
    if (modalOn) {
      const data = e.dataTransfer.getData('text/plain');
      const idIn = cards?.filter((card) => card.id === targetId);
      const dataElement = filteredWithCards?.filter((card) => card.id === data);

      if (dataElement[0]?.rank === null && idIn[0]?.rank !== null) {
        const data2 = JSON.parse(e.dataTransfer.getData('item'));
        moveCardModal(data2, data, targetId);
        setParseElement(null);
        return;
      }
      if (idIn.length > 0) {
        moveCard(data, targetId);
        return;
      } else {
        const data = e.dataTransfer.getData('text/plain');
        if (parseElement) {
          const data2 = JSON.parse(e.dataTransfer.getData('item'));
          moveCardModal(data2, data, targetId);
          setParseElement(null);
        }
      }
    } else {
      const data = e.dataTransfer.getData('text/plain');
      moveCard(data, targetId);
    }
  };
  const ref = useRef(false);
  useOnClickOutside(ref, () => setOpenModal(false));

  const handleClose = () => {
    setOpenModal(false);
  };

  return (
    <div
      className={styles.container}
      style={{
        width: isModal ? '97%' : '100%',
      }}
    >
      <Box
        sx={{...styleBox,
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: palette?.primary,
          },
        }}
        onDrop={(e) => drop(e, openModal ? true : null)}
      >
        {!isModal && (
          <button type="button" className={styles.btOpenModal} onClick={handlerModal}>
            <p className={styles.btOpenModalText}>
              Click here to select articles
            </p>
            <p className={styles.btOpenModalTextsub}>
              Select the articles you want to feature
            </p>
          </button>
        )}
        {Array.isArray(field?.value) && field?.value
          ?.filter((card) => card?.rank !== null)
          .map((post, index) => (
            <Card
              key={post?.id}
              item={post}
              index={index}
              moveCard={moveCard}
              setIsDeleted={handleDelete}
              onDrag={onDrag}
              onDragOver={onDragOver}
              setHoverCard={setHoverCard}
              style={{
                boxShadow:
                  hoverCard === post.id
                    ? `0px 0px 2px 2px ${palette.primary}`
                    : 'none',
              }}
            />
          ))}
        <Modal
          open={openModal}
          onClose={onSubmit}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={{ overflowY: 'auto' }}
        >
          <CardGeneric style={style}>
            <div style={{ display: 'flex', flexDirection: 'row', gap: 10 }}>
              {isFetched && (
                <TextField
                  sx={{ width: '100%', backgroundColor: 'white' }}
                  value={filterValue}
                  variant="outlined"
                  hiddenLabel={true}
                  onChange={handleNameFilter}
                  InputProps={{
                    style: {
                      borderRadius: '10px',
                      fontSize: '14px',
                    },
                    startAdornment: <IoMdSearch size={24} />,
                  }}
                />
              )}
              <FilterPosts
                from="articles"
                handleCategoryFilter={handleCategoryFilter}
                categoryFilter={categoryFilter}
              />
              {/* <Button onClick={onSubmit}>Save</Button> */}
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 10,
                  width: '90%',
                }}
              >
                <Typography fontWeight={600} fontSize={18}>
                  FEATURED ARTICLES
                  <span style={{ color: 'gray', fontSize: 14 }}>
                    {'(UP TO 10)'}
                  </span>
                </Typography>
                <div
                  style={{
                    border: '1px solid grey',
                    borderRadius: '10px',
                    padding: 5,
                  }}
                >
                  <Box
                    sx={{
                      padding: '10px 10px',
                      justifyContent: (Array.isArray(field?.value) && field?.value?.length === 0 )&& 'flex-end',
                      ...styleBox,
                      '&::-webkit-scrollbar-thumb': {
                        backgroundColor: palette?.primary,
                      },
                    }}
                  >
                    {Array.isArray(field?.value) && field?.value
                      ?.filter((card) => card?.rank !== null)
                      .map((post, index) => (
                        <Card
                          key={post?.id}
                          item={post}
                          index={index}
                          moveCard={moveCard}
                          isAllArticles={true}
                          setIsDeleted={handleDelete}
                          onDrag={onDrag}
                          onDragOver={onDragOver}
                          setHoverCard={setHoverCard}
                          style={{
                            boxShadow:
                              hoverCard === post.id
                                ? `0px 0px 2px 2px ${palette.primary}`
                                : 'none',
                          }}
                        />
                      ))}
                    {Array.isArray(field?.value) && field?.value?.length === 0 && (
                      <button
                        style={{
                          cursor: 'auto',
                          height: '120px',
                          width: '120px',
                          border: '1px solid grey',
                          borderRadius: '20px',
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          justifyContent: 'center',
                          flexShrink: 0,
                        }}
                      >
                        <TbHandMove size={25} />
                        <p
                          style={{
                            fontSize: '14px',
                            fontWeight: 700,
                            color: '#515151',
                          }}
                        >
                          Drag and drop files here
                        </p>
                      </button>
                    )}
                  </Box>
                </div>
              </div>
            </div>
            <div style={{ marginTop: 20, width: '100%' }}>
              <Typography fontWeight={600} fontSize={18}>
                ALL ARTICLES
              </Typography>
              <div
                style={{
                  width: '100%',
                  marginTop: 5,
                  display: 'flex',
                  flexDirection: 'row',
                  flexWrap: 'wrap',
                  gap: 20,
                }}
              >
                {isFetched ? (
                  filteredWithCards?.map((post, index) => (
                    <CardAll
                      key={post?.id}
                      item={post}
                      index={index}
                      isAllArticles={true}
                      onDrag={onDrag}
                      onDragOver={onDragOver}
                    />
                  ))
                ) : (
                  <div
                    style={{
                      width: '100%',
                      marginTop: 5,
                      display: 'flex',
                      flexDirection: 'row',
                      flexWrap: 'wrap',
                      gap: 20,
                    }}
                  >
                    <Skeleton variant="rounded" width={200} height={120} />
                    <Skeleton variant="rounded" width={200} height={120} />
                    <Skeleton variant="rounded" width={200} height={120} />
                  </div>
                )}
              </div>
            </div>
          </CardGeneric>
        </Modal>
      </Box>
    </div>
  );
};

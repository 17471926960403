import React, { useState } from 'react';
import { IconButton } from '../Generics/index';
import { AiFillDelete } from 'react-icons/ai';
const iconBtStyle = {
  width: '25px',
  height: '25px',
  borderRadius: '50%',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  outline: 'none',
  padding: 0.5,
  backgroundColor: '#00000082',
};

export const Card = ({
  item,
  setIsDeleted,
  onDrag,
  onDragOver,
  //   setIsDragging,
  style,
  setHoverCard,
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const handlerDelete = (e, item) => {
    e.stopPropagation();
    setIsDeleted(item.id);
  };
  const handleDragEnd = () => {
    // setIsDragging(false);
    setHoverCard(null);
  };
  return (
    <div
      id={item.id}
      onDragEnd={handleDragEnd}
      onDragStart={(e) => {
        onDrag(e, item.id);
      }}
      //   onDragLeave={() => setIsDragging(true)}
      onDragOver={onDragOver}
      draggable="true"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      style={{
        height: '120px',
        position: 'relative',
        width: '200px',
        borderRadius: '10px',
        cursor: 'grab',
        ...style,
      }}
    >
      <img
        src={item?.picture?.original}
        alt="postImagen"
        style={{
          width: '200px',
          height: '120px',
          objectFit: 'cover',
          borderRadius: '10px',
          position: 'relative',
        }}
      />
      <div
        style={{
          position: 'absolute',
          bottom: '0',
          left: '0',
          width: '200px',
          padding: '10px 8px',
          borderRadius: '10px',
          zIndex: 1,
        }}
      >
        {isHovered && (
          <IconButton
            onClick={(e) => handlerDelete(e, item)}
            style={{
              ...iconBtStyle,
              position: 'absolute',
              top: '4px',
              right: '5px',
            }}
            icon={<AiFillDelete size={16} color="white" />}
          />
        )}
        <p
          style={{
            color: 'white',
            fontSize: '14px',
            fontWeight: 700,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            marginTop: '30px',
          }}
        >
          {item?.title}
        </p>
        <p
          style={{
            fontSize: '13px',
            fontWeight: 400,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            color: 'white',
          }}
        >
          {item?.subtitle}
        </p>
      </div>
      <div
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          borderRadius: '10px',
          overflow: 'hidden',
        }}
      >
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            borderRadius: '10px',
            zIndex: 0,
          }}
        ></div>
      </div>
    </div>
  );
};